import React from 'react';

export default function ShareLinks() {
  return (
      <ul className="shareLinks">
        <li className="twitter">
          <a href="https://twitter.com/TheStarlessLand"/>
        </li>

        <li className="tumblr">
          <a href="https://lyraluthuin19.tumblr.com/"/>
        </li>

        <li className="deviantart">
          <a href="https://www.deviantart.com/lyraluthuin"/>
        </li>
      </ul>
  );
}