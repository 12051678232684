import React, { Component } from 'react';
import "../styles/Comic.css";
import Arrows from '../components/Arrows/Arrows.js';
import ComicInfo from '../components/ComicInfo/ComicInfo.js';
import Layout from '../components/Layout.js';
import { navigate } from "@reach/router"

class Comic extends Component {
  constructor(props) {
    super(props);
    let chapterPage = (props.pageContext && props.pageContext.chapterPageId) ? props.pageContext.chapterPageId : null;
    this._chapterPage = chapterPage || 'latest';
    this.state = { page: null, status: null };
    this.comicElRef = React.createRef();
    this.goTo = this.goTo.bind(this);
  }

  set chapterPage(val) {
    if (val === null) return;
    this._chapterPage = val;

    this.fetchPage().then(() => {
      navigate(`/comic/${val}/`).then(() => {
        const offset = this.comicElRef.current ? this.comicElRef.current.offsetTop : 0;

        if (document.documentElement.scrollTop > offset) {
          document.documentElement.scrollTop = offset;
        }
      });
    });
  }

  fetchPage() {
    this.setState({ status: 'LOADING' });
    return window.fetch('/api/pages/' + this._chapterPage)
        .then(response => response.json())
        .then((result) => {
          this.setState({ page: result, status: 'LOADED' });
        }).catch(e => {
          console.error( e);
          this.setState({ status: 'ERROR' });
        });
  }

  componentDidMount() {
    this.fetchPage();
  }

  goTo(typeAndDirection) {
    const page = this.state.page;
    switch(typeAndDirection) {
      case 'PREV_PAGE':
        this.chapterPage = page.prev;
        break;
      case 'NEXT_PAGE':
        this.chapterPage = page.next;
        break;
      case 'FIRST_PAGE':
        this.chapterPage = 'first';
        break;
      case 'LAST_PAGE':
        this.chapterPage = 'latest';
        break;
      case 'PREV_CHAPTER':
        this.chapterPage = page.chapter.prev ? `${page.chapter.prev}_1` : null;
        break;
      case 'NEXT_CHAPTER':
        this.chapterPage = page.chapter.next ? `${page.chapter.next}_1`: null;
        break;
    }
  }

  render() {
    const { page, status } = this.state;

    return (
      <Layout>
        <div className="comic content" ref={this.comicElRef}>
          {status === 'ERROR' ? (<div className="error-alert">Oops, a request to the server failed. Try reloading.</div>) : ""}
          {page ? (
            <div>
              <Arrows goto={this.goTo}/>
              <img className="comicImg" src={page.filePath}/>
              <Arrows goto={this.goTo}/>
              <ComicInfo page={page}/>
            </div>
          ) : ""}
        </div>
      </Layout>
    );
  }
}

export default Comic;
