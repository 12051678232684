import React, { Component } from 'react';
import { Link } from "gatsby";
import avatar from '../../img/MyAvatar-small.jpg';
import bannerClip from '../../img/BannerClip.jpg';
import Disqus from 'disqus-react';
import ShareLinks from '../ShareLinks/ShareLinks.js';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import "./ComicInfo.css";

class ComicInfo extends Component {
  render() {
    const { page } = this.props;
    const publishDate = new Date(page.publishDate);
    const publishStr = page.isPublished ? "Published: " + publishDate.toDateString() : "To be published: " + publishDate.toDateString();
    const disqusShortname = 'thestarlessland';
    const disqusConfig = {
      url: `http://thestarlessland.com/comic/${page.chapterPageId}`,
      identifier: page.chapterPageId,
      title: `${page.chapter.name}: ${page.name}`
    };
    let pageName;
    if (page.chapterPageId.split("_")[0] === "Cover") {
      pageName = page.fullName;
    } else {
      pageName = `${page.chapter.name}: ${page.name}`;
    }

    return (
        <div className="aux-info comicMeta">
          <div className="comicMetaHeader">
            <h1>{pageName}</h1>
            <ShareLinks/>
          </div>
          <div className="aux-info-item">
            {page.authorsComment ? (<div className="avatarWrapper">
              <img className="avatar" alt="Lyraluthuin's avatar" src={avatar}/>
              <p className="authorsComment">{page.authorsComment}</p>
            </div>) : ""}
            <p className="publishMeta">{publishStr}</p>
            <div className="comicInfoTags">
              {page.placeTags.length > 0 ? (<p>Places: {page.placeTags.map((tag) => <Link key={tag} to={"/world/?place=" + tag}>{tag}</Link>)}</p>) : null}
              {page.charTags.length > 0 ? (<p>Characters: {page.charTags.map((tag) => <Link key={tag} to={"/cast/?char=" + tag}>{tag}</Link>)}</p>) : null}
            </div>
          </div>

          <div className="socialMedia">
            <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
            <div className="mediaContainer">
              <div className="twitterEmbed">
                <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="TheStarlessLand"
                    options={{height: 400}}
                />
              </div>
              <div className="additionalMedia">
                <a href="http://topwebcomics.com/vote/25853">
                  <img className="twcBanner" alt="Vote for this comic on TopWebcomics" title="Vote for this comic on TopWebcomics" src={bannerClip}/>
                </a>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default ComicInfo;