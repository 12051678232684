import React, { Component } from 'react';
import "./Arrows.css";

class Arrows extends Component {
  render() {
    const goToFunc = (event, btnAction) => {
      if (this.props.goto) {
        this.props.goto(btnAction);
      }

    };
    return (
        <div className="nav-arrows">
          <div className="prev">
            <button className="prevEnd" aria-label="Go to first page" onClick={(event) => {goToFunc(event, "FIRST_PAGE")}}/>
            <button className="prevChapter" aria-label="Go to previous chapter" onClick={(event) => {goToFunc(event, "PREV_CHAPTER")}}/>
            <button className="prevPage" aria-label="Go to previous page" onClick={(event) => {goToFunc(event, "PREV_PAGE")}}/>
          </div>
          <div className="next">
            <button className="nextPage" aria-label="Go to next page" onClick={(event) => {goToFunc(event, "NEXT_PAGE")}}/>
            <button className="nextChapter" aria-label="Go to next chapter" onClick={(event) => {goToFunc(event, "NEXT_CHAPTER")}}/>
            <button className="nextEnd" aria-label="Go to latest page" onClick={(event) => {goToFunc(event, "LAST_PAGE")}}/>
          </div>
        </div>
    );
  }
}

export default Arrows;
